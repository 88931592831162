import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import { selectedPagesNumber } from '../../redux/actions/viewOrderAction';
import { useDispatch } from 'react-redux';

const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const dispatch = useDispatch();


  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  let lastPage = totalCount ;
  
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
    dispatch(selectedPagesNumber(currentPage));
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
    dispatch(selectedPagesNumber(currentPage - 2));
  };
  
  
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li title="Previous"
        className={classnames('pagination-item  fas fa-arrow-left', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        {/* <div className="arrow left" /> */}
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots" >&#8230;</li>;
        }

        return (
          <li 
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => {onPageChange(pageNumber, dispatch(selectedPagesNumber(pageNumber - 1)))}}
          >
            {pageNumber}
          </li>
        );
      })}
      <li title="Next"
        className={classnames('pagination-item fas fa-arrow-right', {
          disabled: currentPage === lastPage || currentPage === 0
        })}
        onClick={onNext}
      >
        {/* <div className="arrow right" /> */}
      </li>
    </ul>
  );
};

export default Pagination;
