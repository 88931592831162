export const ActionTypes = {
	SET_PRODUCTS: "SET_PRODUCTS",
	SELECTED_PRODUCT: "SELECTED_PRODUCT",
	REMOVE_SELECTED_PRODUCT: "REMOVE_SELECTED_PRODUCT",
	SET_PRODUCTS_LINE: "SET_PRODUCTS_LINE",
	SET_ORDER_LINE: "SET_ORDER_LINE",

	SET_TOKEN: "SET_TOKEN",
	SET_USER_TYPE: "SET_USER_TYPE",
	SET_USER_AUTH: "SET_USER_AUTH",
	REMOVE_USER_AUTH: "REMOVE_USER_AUTH",

	SET_MENU: "SET_MENU",
	ADD_PRODUCT: "ADD_PRODUCT",
	SET_DASHBOARD: "SET_DASHBOARD",
	SET_ORDER_FILTER: "SET_ORDER_FILTER",
	SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
	SET_SELECTED_ORDER: "SET_SELECTED_ORDER",
	SET_PRODUCT_LINE: "SET_PRODUCT_LINE",
	SET_FLAVOUR: "SET_FLAVOUR",
	ADD_TO_CART: "ADD_TO_CART",
	SET_SELECTED_DISTRIBUTOR:"SET_SELECTED_DISTRIBUTOR",
	SET_SELECTED_SALE_PERSON:"SET_SELECTED_SALE_PERSON",
	SET_SHOW_POPUP:"SET_SHOW_POPUP",

	SET_VIEW_ORDER_FILTER: "SET_VIEW_ORDER_FILTER",
	SET_VIEW_ORDER_TOTAL_RECORD:"SET_VIEW_ORDER_TOTAL_RECORD",
	SET_VIEW_ORDER_TOTAL_PAGES:"SET_VIEW_ORDER_TOTAL_PAGES",
	SET_SELECTED_PAGE_NUMBER:"SET_SELECTED_PAGE_NUMBER",

	SET_SELECTED_MSSRPAGE_NUMBER:"SET_SELECTED_MSSRPAGE_NUMBER",
	SET_VIEW_MSSRFILTER:"SET_VIEW_MSSRFILTER",
	SET_VIEW_MSSR_TOTALPAGE:"SET_VIEW_MSSR_TOTALPAGE",
	SET_VIEW_MSSR_TOTALRECORD:"SET_VIEW_MSSR_TOTALRECORD",
	SET_VIEW_MSSR_VALIDATION_STATUS:"SET_VIEW_MSSR_VALIDATION_STATUS",
	SET_VIEW_MSSR_STOCK_DETAILS_LINES:"SET_VIEW_MSSR_STOCK_DETAILS_LINES",
	SET_STOCK_ENTRY_NO:"SET_STOCK_ENTRY_NO",

	SET_SELECTED_INVOICES: "SET_SELECTED_INVOICES",
	SET_DISTRIBUTOR:"SET_DISTRIBUTOR",

	SET_ORDER_FILTER_MSSR:"SET_ORDER_FILTER_MSSR",
	SET_ORDER_DETAILS_MSSR:"SET_ORDER_DETAILS_MSSR",
	SET_PRODUCT_LINE_MSSR:"SET_PRODUCT_LINE_MSSR",
	SET_FLAVOUR_MSSR:"SET_FLAVOUR_MSSR",
	SET_SELECTED_DISTRIBUTOR_MSSR:"SET_SELECTED_DISTRIBUTOR_MSSR",
	SET_SELECTED_SALE_PERSON_MSSR:"SET_SELECTED_SALE_PERSON_MSSR",
	ADD_TO_CART_MSSR:"ADD_TO_CART_MSSR",
	SET_SELECTED_ORDER_MSSR:"SET_SELECTED_ORDER_MSSR",
	SET_SHOW_POPUP_MSSR:"SET_SHOW_POPUP_MSSR",

	SET_NDC_OTP:"SET_NDC_OTP",
	SET_NDC_EXPIRY:"SET_NDC_EXPIRY",
	SET_NDC_HEADER_LIST:"SET_NDC_HEADER_LIST",
	SET_NDC_DETAILS_LINES:"SET_NDC_DETAILS_LINES",
	SET_NDC_APPROVAL_LOGS:"SET_NDC_APPROVAL_LOGS",
	SET_NDC_TABLE_LOADING:"SET_NDC_TABLE_LOADING",
	SET_VIEW_NDC_TOTALRECORD:"SET_VIEW_NDC_TOTALRECORD",
	SET_SELECTED_NDCPAGE_NUMBER:"SET_SELECTED_NDCPAGE_NUMBER",
	SET_SELECTED_NDC_ENTRY_NUMBER :"SET_SELECTED_NDC_ENTRY_NUMBER",
	SET_P2S_TABLE_LOADING:"SET_P2S_TABLE_LOADING",//new
	SET_VIEW_P2S_TOTALRECORD:"SET_VIEW_P2S_TOTALRECORD",//new
	SET_SELECTED_P2SCPAGE_NUMBER:"SET_SELECTED_P2SCPAGE_NUMBER",//new
	SET_SELECTED_P2S_ENTRY_NUMBER:"SET_SELECTED_P2S_ENTRY_NUMBER",//new
	SET_P2S_HEADER_LIST:"SET_P2S_HEADER_LIST",//new
	SET_P2S_DETAILS_LINES:"SET_P2S_DETAILS_LINES",//new
	SET_P2S_APPROVAL_LOGS:"SET_P2S_APPROVAL_LOGS",//new
	SET_P2S_OTP:"SET_P2S_OTP",//new
	SET_P2S_EXPIRY:"SET_P2S_EXPIRY",//new
	SET_SELECTED_P2SPAGE_NUMBER:"SET_SELECTED_P2SPAGE_NUMBER", //06/12/023
	SET_ORDER_DAMAGE: "SET_ORDER_DAMAGE", //05/12/023

	//Added on 28.02.2024 for Damage Declaration

	SET_DAMAGEDISC_OTP:"SET_DAMAGEDISC_OTP", 
  	SET_DAMAGEDISC_EXPIRY:"SET_DAMAGEDISC_EXPIRY", 
  	SET_SELECTED_DAMAGEDISCPAGE_NUMBER:"SET_SELECTED_DAMAGEDISCPAGE_NUMBER",
  	SET_SELECTED_DAMAGEDISC_NUMBER:"SET_SELECTED_DAMAGEDISC_NUMBER",
	SET_DDVIEWHEADER_HEADER_LIST:"SET_DDVIEWHEADER_HEADER_LIST",
	SET_DAMAGEDISC_VALIDATION_STATUS:"SET_DAMAGEDISC_VALIDATION_STATUS",
	SET_DAMAGEDISC_REJECT_STATUS:"SET_DAMAGEDISC_REJECT_STATUS",
	SET_DAMAGEDISC_DETAILS_LINES:"SET_DAMAGEDISC_DETAILS_LINES",
	SET_DDVIEWSEARCHHEADER_TOTAL_RECORD:"SET_DDVIEWSEARCHHEADERBAR_TOTAL_RECORD",
	SET_DAMAGEDISC_TABLE_LOADING:"SET_DAMAGEDISC_TABLE_LOADING",
	SET_SELECTED_DAMAGEDISC_ENTRY_NUMBER:"SET_SELECTEDDAMAGEDISC_ENTRY_NUMBER",
	SET_DAMAGEDISC_APPROVAL_LOGS:"SET_DAMAGEDISC_APPROVAL_LOGS",
	SET_VIEW_DAMAGEDECLARATION_TOTALRECORD:"SET_VIEW_DAMAGEDECLARATION_TOTALRECORD",
	SET_DAMAGEDISC_LOADING:"SET_DAMAGEDISC_LOADING"
};
