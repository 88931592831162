import React from "react";

function Pagenation() {
	return (
		<div className="container">
			<h2>Pagination</h2>
			<p>The .pagination class provides pagination links:</p>
			<ul className="pagination">
				<li>
					<a href="#">1</a>
				</li>
				<li>
					<a href="#">2</a>
				</li>
				<li>
					<a href="#">3</a>
				</li>
				<li>
					<a href="#">4</a>
				</li>
				<li>
					<a href="#">5</a>
				</li>
				x
			</ul>
		</div>
	);
}

export default Pagenation;
